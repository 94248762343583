<template>
  <div class="card">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="input-wrap">
            <label for="title">{{ t('headerTitle') }}
              <span v-if="isHeaderTitleRequired" class="required-star">*</span>
            </label>
            <input v-model="state.header_title" class="form-control" type="text" name="title" id="title"
                   :disabled="!state.header_visible"/>
          </div>
        </div>

        <div class="col-lg-4" style="margin-top: 2.5em">
          <div class="input-wrap">
            <MySwitch v-model="state.header_visible" :label="t('headerIsVisible')"/>
          </div>
        </div>
      </div>
    </div>

    <!--    new child -->

    <div class="container mt-2">
      <div
        class="row plusRow"
        v-for="(item, index) in state.newItems"
        :key="index"
      >
        <div class="col-lg-11">
          <div class="row">
            <div class="col-lg-6">
              <div class="input-wrap">
                <label for="title">{{ t('title') }}
                  <span v-if="index > 0">{{ index }}</span>
                </label>
                <input class="form-control" v-model="item.title" type="text" name="title"/>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-wrap">
                <label>
                  {{ t('platform') }}
                  <span v-if="index > 0">{{ index }}</span>
                </label>
                <select v-model="item.code" name="code" id="code" class="form-control">
                  <option :value="option.code" v-for="(option, index) in state.icons" :key="index">
                    {{ option.code }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="input-wrap">
                <label>Url <span v-if="index > 0">{{ index }}</span></label>
                <input v-model="item.url" type="text" placeholder="https://" class="form-control"/>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-1 rightBtnSet">
          <a @click="item.delete(index)" :title="t('delete')" class="btn btn-outline-danger">
            <i class="pi pi-trash"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="part mt-3" style="display: flex; justify-content: flex-end">
      <div class="input-wrap">
        <a @click="addNewItemRow" :title="t('add')" class="btn btn-outline-primary plus">
          <i class="pi pi-plus"></i> {{ t('addNewItem') }}
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mt-3">
        <div class="input-wrap">
          <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
        </div>
      </div>

      <div class="col-lg-6 mt-3">
        <div class="input-wrap">
          <a :disabled="isFormInvalid" @click="onSubmit" class="btn btn-success">
            {{ t('create.create') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MySwitch from '../../MySwitch'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import useGlobal from '../../../composables/useGlobal'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import AddSocialMediasRequest from '../../../service/RestApi/ShowcaseRequest/Add/AddSocialMediasRequest'

export default {
  components: { MySwitch },
  setup() {
    const { emitter, router, restApi, t, successNotify, errorNotify } =
      useGlobal()

    const state = reactive({
      type: 'SOCIAL_MEDIAS',
      header_title: '',
      header_visible: true,

      icons: [],

      newItems: [{ title: '', code: '', url: '' }],
    })

    // Required checkers
    const isHeaderTitleRequired = computed(() => {
      return ! $v.value.header_title[0].$response
    })

    // Invalid chekers
    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.header_title.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.header_right_title.$error
    })

    const rules = {
      header_title: [requiredIf(() => state.header_visible)],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    const onSubmit = () => {
      const addSocialMediasRequest = new AddSocialMediasRequest(
        'SOCIAL_MEDIAS',
        state.header_title,
        state.header_visible,
        state.newItems,
      ).toParams()

      console.log('addSocialMediasRequest')
      console.log(state.newItems)

      restApi.showcaseAdd(addSocialMediasRequest).then((response) => {

        successNotify(t('showcaseCreated'), t('showcaseCreatedDescription'))

        emitter.emit('showcase-item-added', response.getId())
      }).catch((error) => {

        errorNotify(t('showcaseNotCreated'), t('showcaseNotCreatedDescription') + error)

      })
    }

    const addNewItemRow = () => {
      const newItem = {
        title: '',
        code: '',
        url: '',
      }

      newItem.confirmDelete = () => {

        restApi.deleteShowcaseItemChild(newItem.id).then(() => {
          successNotify(t('showcaseItemDeleted'), t('showcaseItemDeletedDescription'))
          router.go()
        }).catch(() => {
          errorNotify(t('showcaseItemNotDeleted'), t('showcaseItemNotDeletedDescription'))
        })
      }

      newItem.editSubmitExistedChild = () => {
        console.log('submit triggered for new item')
        console.log(newItem.title)
      }

      newItem.delete = (index) => {
        state.newItems.splice(index, 1)
      }

      state.newItems.push(newItem)
    }

    onMounted(() => {
      restApi.fetchIcons().then((response) => {
        state.icons = response
      })
    })

    return {
      state,
      onSubmit,
      cancel,
      restApi,
      t,
      successNotify,
      errorNotify,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,
      addNewItemRow,

      isHeaderTitleRequired,
    }
  },
}
</script>

<style scoped>
.plus,
.minus {
  padding: 1px 8px;
}

.plus .pi-plus,
.minus .pi-minus {
  font-size: 0.7em;
}

.plusRow {
  padding: 1em 0 1em 1em;
  margin-bottom: 1em;
}

.plusRow:nth-child(odd) {
  background-color: #f7f7f778;
}
</style>
