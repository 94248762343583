class AddSocialMediasRequest {

  constructor(type, headerTitle, headerVisible, items) {
    this.type = type || ''
    this.headerTitle = headerTitle || ''
    this.headerVisible = headerVisible || false
    this.items = items || []
  }

  toParams() {

    return {

      type: this.type,

      header: {
        title: this.headerTitle,
        visible: this.headerVisible,
      },
      body: {
        items: this.items
      }
    }
  }
}

module.exports = AddSocialMediasRequest;
